#root > div > div:nth-child(2) > div > div > div:nth-child(2) > div > div > div:nth-child(2) > button{
    /* display: none !important;  */
    /* remove add new field button */
}

#root > div > div:nth-child(2) > div > div > div:nth-child(2) > div > div > div:nth-child(1) > div > div:nth-child(3) > div:nth-child(4) > button > u{
    display: none !important; 
    /* remove bulk field insert */
}

span[aria-label="close"]:not(:empty){
    display: none !important;
}